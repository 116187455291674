import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { CustomSnackbarComponent } from '@app/editor/maintenance-page/maintenance-page.component';
import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogSwUpdatesService {

  constructor(public appRef: ApplicationRef,public updates: SwUpdate, private snackBar: MatSnackBar) {
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(10000);//interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
    
    updates.versionUpdates.pipe(
      filter(event => event.type == "VERSION_READY")//UpdateAvailableEvent
    ).subscribe({
      next: (event: VersionReadyEvent) => {
        this.showUpdateNotification();
      },
      error: (err) => {
        console.log(err);
      }
    })

    updates.versionUpdates.pipe(
      filter(event => event.type === 'VERSION_READY')
    ).subscribe({
      next: (event: VersionReadyEvent) => {
        console.log('Current version is', event.currentVersion);
        console.log('Available version is', event.latestVersion);
        updates.activateUpdate()
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  showUpdateNotification(): void {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 60000,
      data: {
        message: 'Article Editor is ready to update!',
        updates: this.updates
      },
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar']
    });
  }
}
